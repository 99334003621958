// Generated by Framer (10960d4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["QZUn3ShrF"];

const serializationHash = "framer-1cNB7"

const variantClassNames = {QZUn3ShrF: "framer-v-kmzgty"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, tap, title, width, ...props}) => { return {...props, IRRyxhIf0: title ?? props.IRRyxhIf0 ?? "Week 1", zg3cAvnfW: tap ?? props.zg3cAvnfW} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;tap?: any;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, zg3cAvnfW, IRRyxhIf0, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "QZUn3ShrF", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapwovvqo = activeVariantCallback(async (...args) => {
if (zg3cAvnfW) {
const res = await zg3cAvnfW(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-1cNB7", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-kmzgty", className)} data-border data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"QZUn3ShrF"} onTap={onTapwovvqo} ref={ref ?? ref1} style={{"--border-bottom-width": "2px", "--border-color": "var(--token-664a023b-d775-4507-9e20-e9556643cbc0, rgb(24, 165, 231)) /* {\"name\":\"Accent\"} */", "--border-left-width": "2px", "--border-right-width": "2px", "--border-style": "solid", "--border-top-width": "2px", backgroundColor: "var(--token-664a023b-d775-4507-9e20-e9556643cbc0, rgb(24, 165, 231))", borderBottomLeftRadius: 7, borderBottomRightRadius: 7, borderTopLeftRadius: 7, borderTopRightRadius: 7, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7RXhvIDItNzAw", "--framer-font-family": "\"Exo 2\", \"Exo 2 Placeholder\", sans-serif", "--framer-font-size": "22px", "--framer-font-weight": "700", "--framer-line-height": "100%", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))", "--framer-text-transform": "uppercase"}}>Week 1</motion.p></React.Fragment>} className={"framer-1srjb4e"} fonts={["GF;Exo 2-700"]} layoutDependency={layoutDependency} layoutId={"FxQRqFB0o"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={IRRyxhIf0} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-1cNB7 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-1cNB7 .framer-pwon5e { display: block; }", ".framer-1cNB7 .framer-kmzgty { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: auto; justify-content: center; overflow: hidden; padding: 20px 34px 22px 34px; position: relative; width: auto; will-change: var(--framer-will-change-override, transform); }", ".framer-1cNB7 .framer-1srjb4e { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-1cNB7 .framer-kmzgty { gap: 0px; } .framer-1cNB7 .framer-kmzgty > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-1cNB7 .framer-kmzgty > :first-child { margin-left: 0px; } .framer-1cNB7 .framer-kmzgty > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 64
 * @framerIntrinsicWidth 143
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"zg3cAvnfW":"tap","IRRyxhIf0":"title"}
 * @framerImmutableVariables true
 */
const FramerpdQJPgAhl: React.ComponentType<Props> = withCSS(Component, css, "framer-1cNB7") as typeof Component;
export default FramerpdQJPgAhl;

FramerpdQJPgAhl.displayName = "Tab";

FramerpdQJPgAhl.defaultProps = {height: 64, width: 143};

addPropertyControls(FramerpdQJPgAhl, {zg3cAvnfW: {title: "Tap", type: ControlType.EventHandler}, IRRyxhIf0: {defaultValue: "Week 1", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerpdQJPgAhl, [{family: "Exo 2", style: "normal", url: "https://fonts.gstatic.com/s/exo2/v21/7cH1v4okm5zmbvwkAx_sfcEuiD8jWfWcO9q-rpvLpQ.woff2", weight: "700"}])